@import url('https://fonts.googleapis.com/earlyaccess/notosanstc.css');

@import "variables";

@import "common";

body {
    background-color:#fff;
    margin:	40px;
    font-family: Noto Sans CJK TC,FontAwesome,LiHei Pro,儷黑體,Microsoft JhengHei,sans-serif;
    font-size: 12px;
    color: #000;
}
    
.content  {
    padding: 20px 20px 12px;
    background-color: #fff;
}

.error {
    position: absolute;
    width: 500px;
    top: auto;
    left: 50%;
    margin-left: -250px;
    text-align: center;

    @include width-780 {
        width: 200px;
        margin-left: -100px;
    }

    h1 {
        line-height: 1.4;
        font-size: 18px;
        color: #444;
        text-align: center;

        @include width-780 {
            text-align: left;
        }
    }
    
    &-img {
        margin-top: 40px;
        img {
            width: 100%;
        }
    }

    .back {
        display: inline-block;
        width: 100px;
        margin: 40px auto 0;
        line-height: 1.4;
        padding: 4px 30px;
        color: #109af5;
        border: 1px solid #109af5;
        border-radius: 30px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;

        &:hover {
            color: #FFF;
            background: #109af5;
        }
    }
}

