@mixin width-1200 {
    @media screen and (max-width: 1200px) {
      @content;
    }
}


@mixin width-1000 {
    @media screen and (max-width: 1000px) {
      @content;
    }
}

@mixin width-780 {
    @media screen and (max-width: 780px) {
        @content;
    }
}

@mixin width-480 {
    @media screen and (max-width: 480px) {
        @content;
    }
}

@mixin width-320 {
    @media screen and (max-width: 320px) {
        @content;
    }
}

html {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $html-line-height;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0; // 1
    font-family: $font-family-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    color: $body-color;
    text-align: left; // 3
    background-color: $body-bg; // 2
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.btn {
    &-primary {
        color: #fff;
        background-color: #4d98bf;
        border-color: #4d98bf;

        &:hover {
            background-color: #1985ac;
            border-color: #1985ac;
        }
    }

    &-danger {
        color: #fff;
        background-color: #ea5e4c;
        border-color: #ea5e4c;

        &:hover {
            background-color: #d33c31;
            border-color: #d33c31;
        }
    }
}

.lightbox {
    position: fixed;
    z-index: 100;
    width: 750px;
    top: 11%;
    left: 50%;
    margin-left: -375px;
    padding: 30px;
    background: #fff;
    border-radius: 5px;

    &-contain {
        position: absolute;
        z-index: 99;
        height: auto;
        top: 0;
        left: 0;
        right: 0;
        min-height: 100%;
    }

    &-btns {
        margin-top: 30px;

        .btn {
            display: block;
            width: 80%;
            margin: 0 auto;
            background: #4d98bf;
            color: #fff;
            text-align: center;

            &:hover {
                box-shadow: inset 0 0 20px 30px rgba(17, 17, 17, .1)
            }
        }
    }

    &-mask {
        position: fixed;
        z-index: 99;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.7);
    }

    &-err {
        position: relative;
        overflow: hidden;
        top: auto;
        left: auto;
        margin: 40px auto 20px;
        padding-top: 30px;
        background: #fff;
        border: 0;
        border-top: 10px solid #fd7e2d;
        border-radius: 0;
    
        .lightbox-title {
            color: #fd7e2d;
            font-size: 24px;
            font-weight: 400;
            text-align: center;
        }

        .lightbox-msg {
            margin-top: 10px;
            line-height: 1.8;
            font-size: 1rem;
            text-align: center;
            letter-spacing: 1px;
          }
      
        .lightbox-btns {
      
            .hide {
                display: none !important;
        
                &~.col-6 {
                float: none;
                margin: 0 auto;
                }
            }
        }
    
        .fa-close {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 25px;
            height: 32px;
            cursor: pointer;

            &:before {
            font-size: 2rem;
            }

            &:hover {
            color: #f87d2d;
            }
        }
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}